



































import { Research, Target } from '@app/models';
import { Vue, Component, Prop } from 'vue-property-decorator';

import ResearchTargetTabs from '../../components/research/research-target-tabs.vue';
import { br2nl, nl2br } from '../../utils/simple-functions';

@Component({
  components: { ResearchTargetTabs },
})
export default class ResearchIntroduction extends Vue {
  @Prop()
  public value!: Research.Mutable;

  @Prop()
  public targets!: Target[];

  private target = this.targets[0] || '';

  public get introduction() {
    const { introduction } = this.value;
    const text =
      typeof introduction === 'string'
        ? introduction
        : introduction[this.target] || '';
    return br2nl(text);
  }

  public set introduction(value: string) {
    const text = nl2br(value);
    if (typeof this.value.introduction === 'string')
      this.value.introduction = text;
    else this.value.introduction[this.target] = text;
  }

  public resetMessage() {
    const baseKey = 'introduction-text-content';
    const targetKey = `${baseKey}-${this.target}`;

    this.introduction = this.$i18n.exists(targetKey)
      ? this.$t(targetKey)
      : this.$t(baseKey);
  }
}
